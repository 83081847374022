import React, { useState } from 'react';
import { AppBar, Typography, Toolbar, Box, Link } from '@mui/material';
import { AppLogo } from './basic/AppLogo';
import { AppDialog, AppDialogProps } from './AppDialog';
import { AppHelp } from './basic/AppHelp';

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

/**
 * ヘッダー
 * @returns ヘッダー
 */
export const AppHeader = (): JSX.Element => {
  // ステート：ダイアログ
  const [appDialogProps, setAppDialogProps] = useState<AppDialogProps>({
    open: false,
    title: '',
    text: '',
  });

  // イベント：ダイアログ表示
  const handleClickOpen = () => {
    setAppDialogProps({
      open: true,
      title: 'ヘルプ',
      text: <AppHelp></AppHelp>,
    });
  };
  // イベント：閉じる
  const handleClose = () => {
    setAppDialogProps({
      ...appDialogProps,
      open: false,
    });
  };
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }} />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24 }}
          >
            <AppLogo />
          </Link>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              onClick={() => {
                handleClickOpen();
              }}
              style={{ color: 'white', cursor: 'pointer' }}
            >
              {'ヘルプ'}
            </Typography>
            {/* <Link
              variant="h6"
              underline="none"
              href="/"
              sx={{ ...rightLink, color: '#fff' }}
            >
              {'使い方'}
            </Link> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <AppDialog {...appDialogProps} handleClose={handleClose} />
    </div>
  );
};
