import { useState } from 'react';
import { readAsDataURL } from '../lib/utils/fileUtil';

/**
 * アップロードファイル情報を取り扱うhooks
 * @returns
 */
export const useUploadFile = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [encodeData, setEncodeData] = useState<string | null>(null);

  /**
   * ファイルを設定する
   * @param e
   */
  const setFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(null);

    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const file = e.currentTarget.files[0];
      // ファイルサイズを取得
      const fileSize = file.size;
      // 制限サイズ
      const limitSize = 1024 * 1024 * 7.5;
      // ファイルサイズの制限
      if (fileSize > limitSize) {
        throw new Error('ファイルサイズは7.5MB以下にしてください');
      }

      try {
        // ファイルのエンコード
        setEncodeData((await readAsDataURL(file)) as string);
        // ステートにファイルを保持
        setSelectedFile(file);
      } catch (e) {
        throw new Error('ファイルのエンコードに失敗しました');
      }
    }
  };

  return [selectedFile, encodeData, { setFile: setFile }] as const;
};
