import React, { useState } from 'react';
import { Typography, Container, Grid, Box } from '@mui/material';
import { AppCopyright } from './basic/AppCopyright';
import { AppDialog, AppDialogProps } from './AppDialog';
import { AGREEMENT_CONSTANTS } from '../../constants/AgreementConstants';

const links = [{ name: '利用規約' }, { name: 'プライバシーポリシー' }];

/**
 * フッター
 * @returns フッター
 */
export const AppFooter = (): JSX.Element => {
  // ステート：ダイアログ
  const [appDialogProps, setAppDialogProps] = useState<AppDialogProps>({
    open: false,
    title: '',
    text: '',
  });

  // イベント：ダイアログ表示
  const handleClickOpen = (name: string) => {
    if ('利用規約' === name) {
      setAppDialogProps({
        open: true,
        title: name,
        text: AGREEMENT_CONSTANTS.TERMS,
      });
    } else if ('プライバシーポリシー' === name) {
      setAppDialogProps({
        open: true,
        title: name,
        text: AGREEMENT_CONSTANTS.PRIVACY_POLICY,
      });
    }
  };
  // イベント：閉じる
  const handleClose = () => {
    setAppDialogProps({
      ...appDialogProps,
      open: false,
    });
  };

  return (
    <Typography component="footer" sx={{ display: 'flex' }}>
      <Container sx={{ my: 4, display: 'flex' }}>
        <Grid container spacing={5} justifyContent="center">
          {links.map((link) => (
            <Grid item key={link.name}>
              <Grid container justifyContent="center">
                <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                  <Box component="li" sx={{ py: 0.5 }}>
                    <u style={{ color: 'blue' }}>
                      <Typography
                        onClick={() => {
                          handleClickOpen(link.name);
                        }}
                        style={{ color: 'blue', cursor: 'pointer' }}
                      >
                        {link.name}
                      </Typography>
                    </u>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Typography variant="caption">
                <AppCopyright />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <AppDialog {...appDialogProps} handleClose={handleClose} />
    </Typography>
  );
};
