/**
 * アプリケーションロゴ
 * @returns アプリケーションロゴ
 */
export const AppLogo = (): JSX.Element => {
  return (
    <img
      src="logo.png"
      alt="File to Office"
      style={{ maxWidth: '180px', height: 'auto', paddingTop: '0.5em' }}
    />
  );
};
