import React from 'react';
import { Button, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface AppDialogProps {
  open: boolean;
  title: string;
  text: any;
  handleClose?: () => void;
}

/**
 * ダイアログ
 * @param props AppDialogProps
 * @returns ダイアログ
 */
export const AppDialog = (props: AppDialogProps): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <DialogContent>
        <DialogTitle style={{ textAlign: 'center' }}>{props.title}</DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: 'pre-line' }}
        >
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};
