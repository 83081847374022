export const AGREEMENT_CONSTANTS = {
  TERMS: `第1条（目的）
  本利用規約（以下「本規約」といいます。）は、株式会社Ｐ（以下「当社」といいます。）が提供するWEBアプリ「File To Office」（以下「本サービス」といいます。）の利用に関する条件を、本サービスをご利用される方（以下「ご利用者様」といいます。）と当社との間で定めたものです。
  
  第2条（本規約への同意と利用者資格）
  1.	本サービスを利用するためには、本規約及びアプリケーション・プライバシーポリシーに同意していただく必要があります。
  2.	当社は、前項に同意をしていただいたご利用者様が、本サービスをインストールしたその端末において、本サービスを利用する資格を付与します。ご利用者様が本サービスを複数の端末にインストールした利用する場合には、その端末ごとに前項の条件を満たしていただきます。
  3.	ご利用者様は、前項の利用資格を第三者に譲渡することはできません。
  
  第3条（利用料）
  本サービスの利用料は無料とします。ただし、本サービスを利用するために必要な通信機器等の設備、インターネット接続に関わる通信等の費用についてはご利用者様が負担するものとします。
  
  第4条（本サービスが提供する機能）
  ご利用者様は、本サービスにおいて以下の機能を利用することが出来ます。
  ①	ファイルのアップロード
  ②	ファイルのダウンロード
  
  // 削除
  // 第5条（ご利用者様情報の取得）
  // 当社は、本サービスを通じてご利用者様からご利用者様情報として、ファイルのアップロード情報、ダウンロード情報を取得します。

  // 一部修正
  第6条（第三者提供）
  1.	本サービスは、以下の情報収集モジュールにてご利用者様情報を取得・送信・蓄積し、利用目的の達成に必要な範囲で以下に記載の第三者提供をすることがあります。
  【情報収集モジュール名：Google Analytics】
  ①	本サービスには、Googleが提供するGoogle Analyticsが組み込まれています。
  ②	Google Analyticsはcookie（クッキー）を用いて個人を特定する情報を含むことなくご利用者様情報を取得・送信・蓄積します。
  ③	当社は、Google Analyticsが取得・送信・蓄積した情報を本サービスの利用状況を把握するために利用します。
  ④	当社は、Google Analyticsを通じ本項に記載のご利用者様情報をGoogle Inc.へ第三者提供します。
  ⑤	当社は、Google Analyticsによる損害について責任を負わないものとします。
  ⑥	Google Analyticsが取得・送信・蓄積する情報は、Google Analyticsの利用規約に基づき管理されます。
  2.	当社が別途定めるアプリケーション・プライバシーポリシーの第2条に記載の利用目的の達成に必要な範囲内において、ご利用者様情報を第三者に提供することがあります。
  
  第7条（著作権）
  本サービス及び本サービスの機能で表示する情報に関する知的財産権（著作権、特許権、実用新案権、意匠権、商標権、ノウハウ等を含みますが、これに限定されません。）は、当社又はそれぞれの権利の権利者に帰属します。
 
  // 追加
  第8条（アップロードされたファイルについて）
  1.  ご利用者様が本サービスにアップロードしたデータ、そのコンテンツに対する知的所有権はご利用者様が引き続き保持し、ご利用者様が単独で責任を負うものとします。
  2.  ご利用者様の本サービス利用に伴う損失や損害については、当社に一切の責任はないものとします。
  3.  ご利用者様が本サービスにアップロードしたデータを本人の許可なく管理者が閲覧することはありません。ただし、アップロードされたデータが「危険ファイル」として検知された場合、その限りではありません。 詳細については、本規約の「危険ファイル」の項をご参照ください。
  4.  アップロードされたデータは一定期間の経過により自動的に削除されます。削除されたデータファイルは、復元することはできません。

  // 追加
  第9条（禁止ファイル）
  以下に該当するファイルのアップロードを禁止します。
  1.  法令または公序良俗に違反するファイル
  2.  犯罪行為に関連するファイル
  3.  本サービスの運営を妨害するおそれのあるファイル
  4.  当社、ご利用者様に不利益、損害を与えるおそれのあるファイル

  // 一部追記・修正
  第8条（禁止事項）
  ご利用者様は、本サービスの利用に際して、以下の行為をしないものとします。
  禁止事項に関連したファイルを当社で検知した場合、対象となるファイルは「危険ファイル」として取り扱います。詳細については、本規約の「危険ファイル」の項をご参照ください。
  ① 禁止ファイルのアップロード、ダウンロード。
  ①	当社又は第三者の知的財産権を侵害する行為。
  ②	他人の信用・名誉を侵害し、又は他人のプライバシー権、肖像権その他一切の権利を侵害する行為。
  ③	本サービスの提供又は他のご利用者様による本サービスの妨害、もしくはそれらに支障をきたす行為。
  　下記のような行為を指す。
    ・大量のアクセスが見込まれる掲示板などへの特定ファイルのダウンロードURLの貼り付け
    ・通常利用以外のサーバーおよび回線に負荷をかける行為
  ④	法令又は公序良俗に反する行為。
  ⑤ 犯罪行為に関連する行為。
  ⑤	当社のネットワーク又はシステム等に不正にアクセスし、又は不正なアクセスを試みる行為。
  ⑥	その他、当社が合理的な理由に基づき不適切と判断する行為。

  // 追加
  第9条（禁止行為の検知）
  禁止行為は、以下の方法で検知します。
  1. ユーザーからのお問い合わせ等、通報によるもの
  2. 本サービスの管理プログラムによるもの
     この時、当社による人的検閲は行いません。
     （プログラムの詳細については、セキュリティ上お答えできません。）

  // 追加
  第10条（危険ファイル）
  「危険ファイル」として検知されたファイルは、運営者がファイル情報を確認し削除できるものとします。

  第9条（免責）
  1.	当社は、本サービスがご利用者様の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ご利用者様による本サービスの利用がご利用者様に適用のある法令又は業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
  2.	当社は、ご利用者様が本サービスを利用すること、または、利用できなかったことによって損害、トラブル等が生じた場合であっても、いかなる責任も負いません。
  3.	当社は、ご利用者様が本サービスを利用することに起因、関係して発生した事故・トラブル、本サービスの利用によるご利用者様の機器の不具合、または本サービスに表示される情報及びその変更、更新によりご利用者様が損害を被った場合であっても、一切の責任を負わないものとします。
  4.	当社は、ご利用者様が本サービスを利用することによって、ご利用者様の就職活動の成功を保証するものではありません。
  5.	当社は、本サービスに表示される情報等及びその変更、更新等に関連して、ご利用者様に生じた一切の損害、トラブルに関していかなる責任も負いません。
  
  第10条（本サービスの変更、追加）
  当社は、ご利用者様に事前の通知をすることなく、本サービスの内容の全部又は一部を変更、新たに追加する場合があり、ご利用者様はこれを承諾するものとします。
  
  第11条（利用停止と損害賠償）
  ご利用者様が本規約に定める事項に違反した場合、又は、ご利用者様が本サービスの利用を継続することで他のご利用者様若しくは当社に不利益や損害が発生するおそれがあると当社が判断した場合、当社は、直ちに当該ご利用者様の本サービスの利用を、方法を問わず停止できるものとします。また、ご利用者様が本規約に違反した結果、当社が損害を被った場合、その損害をご利用者様が負担するものとします。
  
  第12条（本規約の変更）
  1.	当社は、当社が必要と認めた場合は、本規約を変更できるものとします。
  2.	本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知します。ただし、法令上、ご利用者様の同意が必要となるような内容の変更の場合は、当社所定の方法でご利用者様の同意を得るものとします。
  
  第13条（法令等の遵守）
  ご利用者様は、本サービスの利用にあたり、本規約に加え、関連する法律、政令、省令、条例、規約及び命令等を遵守するものとします。
  
  第14条（分離可能性）
  本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
  
  第15条（準拠法及び裁判管轄）
  本規約は日本法に準拠します。また、当社とご利用者様との間で紛争が生じた場合、名古屋地方裁判所を第一審の専属的合意管轄裁判所とします。
  
  // 日程仮置き
  本規約は2022年3月31日より実施するものとします。
  
  2022年3月31日制定
  
`,
  PRIVACY_POLICY: `株式会社Ｐ（以下「当社」といいます。）は、当社の提供する「File To Office」（以下「本サービス」といいます。）の利用者（以下「ご利用者様」といいます。）に関する個人情報を含んだ利用者様情報（以下「利用者様情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。

  1.	収集する利用者様情報等
  本ポリシーにおいて、「利用者様情報」とは、ご利用者様の識別に係る情報、通信サービス上の行動履歴、その他ご利用者様又はご利用者様の端末に関連して生成又は蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。本サービスにおいて、当社が収集する利用者様情報は、ご利用者様のファイルのアップロード情報、及びダウンロード情報となります。
  
  // 一部削除
  2.	利用目的
  本サービスのサービス提供にかかわる利用者様情報の具体的な利用目的は以下のとおりです。
  // ①	本サービスに関する登録の受付
  ②	ご利用者様のトラフィック測定及び行動測定のため
  ③	本サービスの調査や分析のため
  ④	本サービスに関するご案内、お問い合わせ等への対応のため
  ⑤	本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
  ⑥	本サービスに関する規約等の変更などを通知するため
  ⑦	当社の商品やサービスの品質向上、新規開発のため
  
  3.	情報収集モジュールの有無
  本サービスには、利用状況の分析を目的として、次に掲げる情報収集モジュールが含まれています。情報収集モジュールにより取得される情報、取得された情報の利用目的等については、情報収集モジュールの提供者のプライバシーポリシーをご確認ください。
  ・情報収モジュール名称：Google Analytics
  ・情報モジュール提供者:Google, Inc.
  ・提供される利用者情報の項目：アプリコンテンツ別のテンプレートの利用回数
  　・第三者提供の有無：Google, Inc.
  ・上記提供者のプライバシーポリシー：https://policies.google.com/privacy?hl=ja
  
  4.	第三者提供
  当社は、利用者様情報のうち、個人情報については、あらかじめご利用者様の同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。ただし、次に掲げる必要があり、第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
  ①	当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
  ②	合併その他の事由による事業の承継に伴って個人情報が提供される場合
  ③	第3項の定めに従って、情報収集モジュール提供者へ個人情報が提供される場合
  ④	国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご利用者様の同意を得ることによって当該事務の遂行に使用を及ばす恐れがある場合
  ⑤	その他、個人情報の保護に関する法律その他の法令で認められる場合
  
  5.	個人情報の開示、訂正及び利用停止等
  当社は、ご利用者様ご本人から個人情報の内容の開示・訂正・利用停止等の請求があった場合、個人情報保護法に基づき適切・迅速に対応いたします。
  
  6.	お問い合わせ窓口
  ご意見、ご質問等のお問い合わせは、下記の窓口までお願いいたします。
  
  住所：愛知県名古屋市中区大須4丁目10番40号カジウラテックスビル8階
  株式会社Ｐ
  個人情報取扱主任者：情報セキュリティ担当
  Mail: contact@pcompany.jp
  
  7.	プライバシーポリシーの変更手続
  当社は、当社が必要と認めた場合は、本ポリシーを変更できるものとします。本ポリシーを変更する場合、変更後の本ポリシーの施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知します。ただし、法令上、ご利用者様の同意が必要となるような内容の変更の場合は、当社所定の方法でご利用者様の同意を得るものとします。
  
  2022年3月31日制定  
  `,
};
