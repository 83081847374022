import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
/**
 * アプリケーションヘルプ画面
 * @returns アプリケーションヘルプ画面
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`how-to-use-tabpanel-${index}`}
      aria-labelledby={`how-to-use-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index: number) {
  return {
    id: `how-to-use-tab-${index}`,
    'aria-controls': `how-to-use-tab-${index}`,
  };
}

export const AppHelp = (): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
        File To Officeとは？
      </Typography>
      <p>
        ファイルのアップロード・ダウンロードがユーザー登録・利用料不要で使用できるサービスです。
        <br />
      </p>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="how to use tabs"
        >
          <Tab label="アップロード方法" {...tabProps(0)} />
          <Tab label="ダウンロード方法" {...tabProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <p>
          ①「ファイルを選択」押下または下記エリアにドラッグ&ドロップでアップロードしたいファイルを選択
          <br />
          　※【注意】1度のアップロードには以下の制限があります
          <br />
          　・ファイル数は<u>1ファイル</u>のみ
          <br />
          　・ファイル容量は<u>7.5MB</u>までアップロード可能
          <br />
        </p>
        <img
          src="help01.png"
          alt="使い方①"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>
          ②選択後表示される「アップロードする」ボタンを押下でアップロード開始
        </p>
        <img
          src="help02.png"
          alt="使い方②"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>
          ③アップロード成功でアップロードファイル情報が表示される
          <br />
          　※「コピーする」ボタン押下で一括コピー可能
        </p>
        <img
          src="help03.png"
          alt="使い方③"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>
          ④③で表示された情報の「ダウンロードURL」と「パスワード」を利用することでファイルをダウンロードすることができる
        </p>
        <img
          src="help04.png"
          alt="使い方④"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <p>
          ①アップロード時に取得した「ダウンロードURL」を使用するとダウンロード画面が表示される
        </p>
        <img
          src="help05.png"
          alt="使い方⑤"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>
          ②アップロード画面で取得した「パスワード」を入力し「送信」ボタンを押下
          <br />
          　※パスワード入力に5回失敗するとダウンロード制限がかかります
        </p>
        <img
          src="help06.png"
          alt="使い方⑥"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>
          ③パスワード送信に成功すると「ダウンロードファイル情報」と「ダウンロード」ボタンが表示される
        </p>
        <img
          src="help07.png"
          alt="使い方⑦"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
        <p>④「ダウンロード」ボタン押下でファイルがダウンロードされる</p>
        <img
          src="help08.png"
          alt="使い方⑧"
          style={{ maxWidth: '100%', paddingTop: '0.5em' }}
        />
      </TabPanel>
    </div>
  );
};
