import { Container, Typography } from '@mui/material';
import { AppFooter } from '../parts/AppFooter';
import { AppHeader } from '../parts/AppHeader';

interface BasePageTemplateProps {
  children: React.ReactNode;
  title: string;
}
/**
 * ページテンプレート
 * @returns ページテンプレート
 */
export const BasePageTemplate = (props: BasePageTemplateProps): JSX.Element => {
  return (
    <Container component="main">
      <AppHeader />
      <Container sx={{ my: 4 }}>
        <Typography component="h2" variant="h6" align="center">
          {props.title}
        </Typography>
        <Container sx={{ my: 2 }}>{props.children}</Container>
      </Container>

      <hr />
      <AppFooter />
    </Container>
  );
};
