import { Button, ButtonProps } from '@mui/material';
import { Progress } from './basic/Progress';

interface ProgressButtonProps extends ButtonProps {
  loading: boolean | undefined;
}

/**
 * ローディング中制御ボタン
 * @returns ローディング中制御ボタン
 */
export const ProgressButton = (props: ProgressButtonProps): JSX.Element => {
  // 拡張したpropsを分離する
  const { loading, ...buttonProps } = props;
  return (
    <Button
      {...buttonProps}
      disabled={loading === true || buttonProps.disabled === true}
      style={{ position: 'relative' }}
    >
      {props.children}
      {loading && (
        <Progress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Button>
  );
};
