import { HashRouter, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { appTheme } from './appTheme';
import { UploadPage } from './components/pages/UploadPage';
import { DownloadPage } from './components/pages/DownloadPage';

/**
 * ReactQueryの初期設定
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // リトライなし
      refetchOnWindowFocus: false, // Windowフォーカス時に再取得しない
      staleTime: 5 * 60 * 1000, // キャッシュの保持期間（ミリ秒）
    },
  },
});
/**
 * Webアプリケーションのルートコンポーネント
 * @returns ルートコンポーネント
 */
const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools /> */}
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <HashRouter>
          <Switch>
            <Route path="/" component={UploadPage} exact />
            <Route path="/upload" component={UploadPage} exact />
            <Route path="/download" component={DownloadPage} exact />
            <Route path="/download/:key" component={DownloadPage} exact />
          </Switch>
        </HashRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
