import React, { useState } from 'react';
import { useMutation } from 'react-query';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { ProgressButton } from '../parts/ProgressButton';
import { BasePageTemplate } from '../templates/BasePageTemplate';
import { useUploadFile } from '../../hooks/useUploadFile';
import { formattFileSize } from '../../lib/utils/fileUtil';
import { ENV_CONSTANTS } from '../../constants/EnvConstants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PAGE_TITLE = 'アップロード';

/**
 * ファイルアップロードページ
 * @returns React関数コンポーネント
 */
export const UploadPage = (): JSX.Element => {
  /* ステート */
  // ステート：アップロード結果
  const [uploadResponse, setUploadResponse] = useState({
    fileName: '',
    downloadKey: '',
    storageFolderId: '',
    password: '',
    storageFolderHoldingDays: '',
    fileSize: '',
  });

  /* フック */
  // フック：アップロードファイルの保持
  const [selectedFile, encodeData, { setFile }] = useUploadFile();
  // フック：アップロードAPI
  const mutationFileupload = useMutation(
    (data: any) => {
      return fetch(`${ENV_CONSTANTS.API_URL}storagefile/fileUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'P-Api-Key': `${ENV_CONSTANTS.API_KEY}`,
        },
        body: JSON.stringify(data),
      });
    },
    {
      /* 更新失敗時処理 */
      onError: async (error: unknown) => {
        console.log('Error：サーバーへのアクセスに失敗しました', error);
        console.log(error);
      },
      /* 更新成功時処理 */
      onSuccess: async (data: Response) => {
        const jsonResponse = await data.json();
        setUploadResponse({
          fileName: jsonResponse.fileName,
          downloadKey: jsonResponse.downloadKey,
          storageFolderId: jsonResponse.storageFolderId,
          password: jsonResponse.password,
          storageFolderHoldingDays: jsonResponse.storageFolderHoldingDays,
          fileSize: formattFileSize(jsonResponse.fileSize),
        });
      },
    }
  );

  /* イベント */
  // アップロード処理
  const upload = async () => {
    // APIの呼び出し
    const params = {
      file: encodeData,
      name: selectedFile!.name,
      fileSize: selectedFile!.size,
      // bucketName: ENV_CONSTANTS.BUCKET_NAME,
      // fileInfoTable: ENV_CONSTANTS.FILE_INFO_TABLE,
      // folderInfoTable: ENV_CONSTANTS.FOLDER_INFO_TABLE,
    };

    // APIの呼出し
    mutationFileupload.mutate(params);
  };

  // クリップボードコピー
  const copyUploadResultToClipboard = async () => {
    await navigator.clipboard.writeText(
      document.getElementById('textfield-upload-result')?.textContent!
    );
    console.log('コピーしました');
  };

  // ステート：ダイアログ
  const [open, setOpen] = React.useState(false);

  // イベント：ダイアログ表示
  const handleClickOpen = () => {
    setOpen(true);
  };

  // イベント：ダイアログ閉じる
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BasePageTemplate title={PAGE_TITLE}>
      <Stack spacing={2}>
        <Typography>アップロードするファイルを選択してください。</Typography>
        <input
          type="file"
          onChange={async (e) => {
            try {
              await setFile(e);
            } catch (error) {
              alert(error);
              e.target.value = '';
            }
          }}
        />
        {selectedFile ? (
          <ProgressButton
            onClick={() => {
              upload();
              handleClickOpen();
            }}
            loading={mutationFileupload.isLoading}
            startIcon={<FileUploadIcon />}
          >
            アップロードする
          </ProgressButton>
        ) : null}
        {selectedFile && mutationFileupload.isSuccess ? (
          <Card>
            <CardContent>
              <Typography>
                アップロードが完了しました。以下の内容を送信相手に連絡してください。
              </Typography>
              <TextField
                id="textfield-upload-result"
                multiline
                fullWidth
                maxRows={10}
                value={`■ファイル名
${uploadResponse.fileName}
■ダウンロードURL
${ENV_CONSTANTS.DOWNLOAD_URL}${uploadResponse.downloadKey}
■パスワード
${uploadResponse.password}
■ダウンロード有効期限
${uploadResponse.storageFolderHoldingDays}
■ファイルサイズ
${uploadResponse.fileSize}`}
              />
            </CardContent>
            <CardActions>
              <Button
                color="secondary"
                fullWidth
                onClick={copyUploadResultToClipboard}
                startIcon={<FileCopyIcon />}
              >
                コピーする
              </Button>
            </CardActions>
          </Card>
        ) : null}
        {mutationFileupload.isError ? (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ファイルのアップロードに失敗しました。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Stack>
    </BasePageTemplate>
  );
};
