/**
 * ファイルサイズを単位つきの文字列に変換する
 * @param byteSize
 * @returns ファイルサイズの表記文字列
 */
export const formattFileSize = (byteSize: number): string => {
  const kb = 1024;
  const mb = Math.pow(kb, 2);
  const gb = Math.pow(kb, 3);
  const tb = Math.pow(kb, 4);

  const round = (fileSize: number, unit: number) => {
    return Math.round((fileSize / unit) * 100.0) / 100.0;
  };

  if (byteSize >= tb) {
    return round(byteSize, tb) + 'TB';
  } else if (byteSize >= gb) {
    return round(byteSize, gb) + 'GB';
  } else if (byteSize >= mb) {
    return round(byteSize, mb) + 'MB';
  } else if (byteSize >= kb) {
    return round(byteSize, kb) + 'KB';
  }
  return byteSize + 'byte';
};

/**
 * ファイルダウンロード処理
 * APIから取得したファイルデータをブラウザに渡す
 * @param fileName ファイル名
 * @param blob バイナリデータ
 */
export const executeDownload = (fileName: string, blob: Blob) => {
  // blobオブジェクトを参照するURLを作成
  const url = URL.createObjectURL(blob);
  // aタグの生成
  const tmpATag = document.createElement('a');
  // htmlのbodyタグにaタグを追加
  document.body.appendChild(tmpATag);
  // aタグのdownload属性にファイル名を指定する
  tmpATag.download = fileName;
  // aタグのherf属性にblobオブジェクトを参照するURLを挿入
  tmpATag.href = url;
  // aタグクリック
  tmpATag.click();
  // aタグ削除
  tmpATag.remove();
};

/**
 * FileReader.readAsDataURLをPromiseでラップする
 * ※Promiseでラップすることでasync/awaitで制御ができるようになる
 * @param blob バイナリデータ
 * @returns Promiseオブジェクト
 */
export const readAsDataURL = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(blob);
  });
};
