import React, { Context, useState } from 'react';
import { useMutation } from 'react-query';

import { Grid, Stack, TextField, Typography, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { executeDownload, formattFileSize } from '../../lib/utils/fileUtil';
import { ProgressButton } from '../parts/ProgressButton';
import { BasePageTemplate } from '../templates/BasePageTemplate';
import { ENV_CONSTANTS } from '../../constants/EnvConstants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PAGE_TITLE = 'ダウンロード';

// ダウンロードコンポーネントの処理関数
export const DownloadPage = (): JSX.Element => {
  /* ステート */
  // ステート：ダウンロードボタンの表示
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  // ステート：パスワード
  const [password, setPassword] = useState('');
  // ステート：パスワードエラーフラグ
  const [passwordErrFlag, setPasswordErrFlag] = useState(false);
  // ステート：メッセージ
  const [message, setMessage] = useState('');

  // ステート：パスワード認証結果
  const [sendPasswordResponse, setSendPasswordResponse] = useState({
    storageFolderId: '',
    fileName: '',
    uploadDate: '',
    fileSize: '',
    fileMaxHoldingDays: '',
  });
  /* フック */
  // フック：パスワード認証API
  const mutationSendPassword = useMutation(
    (data: any) => {
      return fetch(`${ENV_CONSTANTS.API_URL}certification/sendPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'P-Api-Key': `${ENV_CONSTANTS.API_KEY}`,
        },
        body: JSON.stringify(data),
      });
    },
    {
      /* 更新失敗時処理 */
      onError: async (error: unknown) => {
        console.log('Error：サーバーへのアクセスに失敗しました', error);
      },
      /* 更新成功時処理 */
      onSuccess: async (res: Response) => {
        if (!res.ok) {
          setPasswordErrFlag(true);
          try {
            const jsonResponse = await res.json();
            const messageId = jsonResponse.messageId;
            if ('ME0001' === messageId) {
              setMessage('パスワードの入力制限がかかっています。');
            } else if ('ME0002' === messageId) {
              const waitTime = jsonResponse.time;
              setMessage(
                `パスワード試行回数の上限に達しました。\n` +
                  waitTime +
                  `分後に再度パスワードを入力してください。`
              );
            } else if ('ME0003' === messageId) {
              setMessage('パスワード認証に失敗しました。');
            } else if ('ME0004' === messageId) {
              setMessage('データの更新に失敗しました。');
            } else if ('ME0005' === messageId) {
              setMessage('データの検索に失敗しました。');
            } else if ('ME0006' === messageId) {
              setMessage('URLが正しくありません。');
            }
          } catch (e) {
            console.log('サーバーエラー');
            setMessage('パスワード認証に失敗しました。');
          }
          // 例外を発生させる
          // res.status = HTTPのステータスコード(404等)
          // res.statusText = ステータスの内容(Not Found等)
          throw new Error(`${res.status} ${res.statusText}`);
        }
        console.log('Success：レスポンスの受け取りに成功しました。');
        const jsonResponse = await res.json();
        setSendPasswordResponse({
          storageFolderId: jsonResponse.storageFolderId,
          fileName: jsonResponse.fileName,
          uploadDate: jsonResponse.uploadDate,
          fileSize: formattFileSize(jsonResponse.fileSize),
          fileMaxHoldingDays: jsonResponse.fileMaxHoldingDays,
        });
        setPassword('');
        setShowDownloadButton(true);
      },
    }
  );

  // フック：ファイルダウンロードAPI
  const mutationFileDownload = useMutation(
    (data: any) => {
      return fetch(`${ENV_CONSTANTS.API_URL}storagefile/fileDownload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'P-Api-Key': `${ENV_CONSTANTS.API_KEY}`,
        },
        body: JSON.stringify(data),
      });
    },
    {
      /* 更新失敗時処理 */
      onError: async (error: unknown) => {
        console.log('Error：サーバーへのアクセスに失敗しました', error);
        console.log(error);
        alert('サーバーへのアクセスに失敗しました');
      },
      /* 更新成功時処理 */
      onSuccess: async (res: Response) => {
        // サーバーからのレスポンス(ファイル)の受け取りに失敗した場合
        if (!res.ok) {
          // 例外を発生させる
          // res.status = HTTPのステータスコード(404等)
          // res.statusText = ステータスの内容(Not Found等)
          throw new Error(`${res.status} ${res.statusText}`);
        }
        // レスポンス成功確認
        console.log('Success：レスポンスの受け取りに成功しました');
        // ファイルの受け取り成功時
        // サーバーから受け取ったファイルをblobオブジェクトを使用し、バイナリデータとして読み込む（出力する）
        const blob = await res.blob();

        // blobオブジェクトを受け取り後、クライアント側でファイルリンク生成し、ダウンロード処理を完結させる。
        executeDownload(sendPasswordResponse.fileName, blob);
      },
    }
  );

  /* イベント */
  /**
   * パスワード入力時処理
   * @param e 入力イベント
   */
  const handleChange = (e: any) => {
    // 入力値をステートに設定する
    setPassword(() => e.target.value);
  };

  /**
   * 送信ボタン押下時の処理
   * @param password パスワード
   */
  const sendPassword = (password: string) => {
    const url = window.location.href;
    const downloadKey = url.replace(`${ENV_CONSTANTS.DOWNLOAD_URL}`, '');
    setPasswordErrFlag(false);

    // リクエスト時送信データの設定
    const data = {
      fileName: '',
      uploadDate: '',
      fileSize: '',
      fileMaxHoldingDays: '',
      password: password,
      downloadKey: downloadKey,
    };
    // APIの呼出し
    mutationSendPassword.mutate(data);
  };

  /**
   * ダウンロードボタン押下時の処理
   * @param data id：ファイルID、fileName：ファイル名
   */
  const fileDownload = async (data: { id: string; fileName: string }) => {
    // APIの呼出し
    mutationFileDownload.mutate(data);
  };

  // ステート：ダイアログ
  const [open, setOpen] = React.useState(false);

  // イベント：ダイアログ表示
  const handleClickOpen = () => {
    setOpen(true);
  };

  // イベント：ダイアログ閉じる
  const handleClose = () => {
    setOpen(false);
  };

  // 画面に表示するダウンロードコンポーネント
  return (
    <BasePageTemplate title={PAGE_TITLE}>
      <Stack spacing={2}>
        {!showDownloadButton ? (
          <Typography>
            ファイルダウンロード用のパスワードを入力してください
          </Typography>
        ) : null}
        {!showDownloadButton ? (
          <Grid
            container
            spacing={{ xs: 0, sm: 1, md: 1 }}
            rowSpacing={{ xs: 1, sm: 0, md: 0 }}
          >
            <Grid item xs={12} sm={8} md={10}>
              <TextField
                type="password"
                placeholder="パスワード"
                value={password}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <ProgressButton
                type="button"
                onClick={() => {
                  sendPassword(password);
                  handleClickOpen();
                }}
                loading={mutationSendPassword.isLoading}
                startIcon={<LockIcon />}
                fullWidth
              >
                送信
              </ProgressButton>
            </Grid>
          </Grid>
        ) : null}
        {showDownloadButton ? (
          <Grid container rowSpacing={1}>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" display="block" gutterBottom>
                ファイル名
              </Typography>
              <Typography>{sendPasswordResponse.fileName}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" display="block" gutterBottom>
                ファイルサイズ
              </Typography>
              <Typography>{sendPasswordResponse.fileSize}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" display="block" gutterBottom>
                アップロード日時
              </Typography>
              <Typography>{sendPasswordResponse.uploadDate}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="caption" display="block" gutterBottom>
                ダウンロード有効期限
              </Typography>
              <Typography>{sendPasswordResponse.fileMaxHoldingDays}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ProgressButton
                type="button"
                onClick={() =>
                  fileDownload({
                    id: sendPasswordResponse.storageFolderId,
                    fileName: sendPasswordResponse.fileName,
                  })
                }
                loading={mutationFileDownload.isLoading}
                startIcon={<FileDownloadIcon />}
                fullWidth
              >
                ダウンロード
              </ProgressButton>
            </Grid>
          </Grid>
        ) : null}
        {passwordErrFlag ? (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ whiteSpace: 'pre-line' }}
              >
                {message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Stack>
    </BasePageTemplate>
  );
};
